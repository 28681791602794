import { ButtonWithIcon, ConditionalContentTrigger, ResponsiveForm, ThemeColor, ThemeShade } from '@papa/components';
import { actionButtonStyling, StyledLink, StyledSeparator } from '../../styles/FormElements';
import styled from 'styled-components';
import dropins from '../../emails/dropins.json';
import { JSX } from 'react';
import { ICustomTheme } from '../../styles/theme';

export const yesOrNoAnswers = [
    { label: 'Yes', value: 'Y' },
    { label: 'No', value: 'N' },
];

interface IWrapperProps {
    children: JSX.Element | JSX.Element[];
}

export const CustomFormWrapper = (props: IWrapperProps) => {
    return (
        <>
            <ResponsiveForm.Root>{props.children}</ResponsiveForm.Root>
            <StyledSeparator orientation="horizontal" weight="thin" color={ThemeColor.neutral} shade={ThemeShade.light} />
        </>
    );
};

interface ISubtextProps {
    children: string | JSX.Element | JSX.Element[];
}
export const Subtext = styled.p`
    font-size: 0.9em;
    margin: 0;
    color: ${props => props.theme.secondary.light};
    font-family: ${props => props.theme.font.italic};
`;
export const ItalicSubtext = (props: ISubtextProps) => {
    return (
        <Subtext>
            <i>{props.children}</i>
        </Subtext>
    );
};

export const ErrorSubtext = styled.p`
    font-size: 0.9em;
    margin: 0;
    color: ${props => props.theme.error.dark};
    font-family: ${props => props.theme.font.italic};
`;

export const ErrorItalicSubtext = (props: ISubtextProps) => {
    return (
        <ErrorSubtext>
            <i>{props.children}</i>
        </ErrorSubtext>
    );
};

const uppercaseTextStyle = (props: { theme: ICustomTheme }) => `
    font-family: ${props.theme.font.display};
    text-transform: uppercase;
`;

export const UppercaseSubheader = styled.h3`
    color: ${props => props.theme.secondary.dark};
    font-size: 1em;
    margin: 1.25rem 0 0.5rem 0;
    letter-spacing: 0.4rem;
    font-weight: 900;
    ${uppercaseTextStyle}
`;

export const UppercaseSubtext = styled.span`
    font-size: 0.7em;
    letter-spacing: 0.1rem;
    font-weight: 700;
    ${uppercaseTextStyle}
`;

export const PartnerMailToLink = <StyledLink href={`mailto:${dropins.partner_email}`}>{dropins.partner_email}</StyledLink>;

export const StyledConditionalContentTrigger = styled(ConditionalContentTrigger.Root)`
    &${ButtonWithIcon.Button} {
        ${actionButtonStyling}
        font-size: 1.1em;
        color: ${props => props.theme.neutral.faint};
        min-width: 15rem;
        width: fit-content;
        text-decoration: none;
        margin: 1rem 0;
    }
`;

export const CollegeAddressText = styled.p`
    color: ${props => props.theme.primary.main};
    font-size: 1.125em;
    font-style: italic;
    margin: 0;

    @media screen and (max-width: ${props => props.theme.layout.tablet}) {
        font-size: 1em;
    }
`;
