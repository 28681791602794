import { QueryClient } from '@tanstack/react-query';

// if you add a yupValueKey for a radio or select in the UserGeneratedAnswersEnum, you will need to add a corresponding yupValueKey for the description, specifically for radio inputs and selects (including those in AutoComplete components)
export enum AutoGeneratedAnswersEnum {
    gender_desc = 'gender_desc',
    mailing_state_desc = 'mailing_state_desc',
    mailing_country_desc = 'mailing_country_desc',
    birth_country_desc = 'birth_country_desc',
    birth_state_desc = 'birth_state_desc',
    citizen_country_desc = 'citizen_country_desc',
    us_study_radio_desc = 'us_study_radio_desc',
    us_residency_radio_desc = 'us_residency_radio_desc',
    citizenship_visa_status_desc = 'citizenship_visa_status_desc',
    intended_visa_type_desc = 'intended_visa_type_desc',
    military_tuition_assistance_desc = 'military_tuition_assistance_desc',
    military_branch_desc = 'military_branch_desc',
    military_status_specified_desc = 'military_status_specified_desc',
    military_spouse_status_desc = 'military_spouse_status_desc',
    military_spouse_benefits_desc = 'military_spouse_benefits_desc',
    prev_attend_from_month_desc = 'prev_attend_from_month_desc',
    prev_attend_to_month_desc = 'prev_attend_to_month_desc',
    prev_attend_from_year_desc = 'prev_attend_from_year_desc',
    prev_attend_to_year_desc = 'prev_attend_to_year_desc',
    college_2_degree_desc = 'college_2_degree_desc',
    college_2_attend_from_month_desc = 'college_2_attend_from_month_desc',
    college_2_attend_to_month_desc = 'college_2_attend_to_month_desc',
    college_2_attend_from_year_desc = 'college_2_attend_from_year_desc',
    college_2_attend_to_year_desc = 'college_2_attend_to_year_desc',
    college_2_state_desc = 'college_2_state_desc',
    college_3_degree_desc = 'college_3_degree_desc',
    college_3_attend_from_month_desc = 'college_3_attend_from_month_desc',
    college_3_attend_to_month_desc = 'college_3_attend_to_month_desc',
    college_3_attend_from_year_desc = 'college_3_attend_from_year_desc',
    college_3_attend_to_year_desc = 'college_3_attend_to_year_desc',
    college_3_state_desc = 'college_3_state_desc',
    college_4_degree_desc = 'college_4_degree_desc',
    college_4_attend_from_month_desc = 'college_4_attend_from_month_desc',
    college_4_attend_to_month_desc = 'college_4_attend_to_month_desc',
    college_4_attend_from_year_desc = 'college_4_attend_from_year_desc',
    college_4_attend_to_year_desc = 'college_4_attend_to_year_desc',
    college_4_state_desc = 'college_4_state_desc',
    college_5_degree_desc = 'college_5_degree_desc',
    college_5_attend_from_month_desc = 'college_5_attend_from_month_desc',
    college_5_attend_to_month_desc = 'college_5_attend_to_month_desc',
    college_5_attend_from_year_desc = 'college_5_attend_from_year_desc',
    college_5_attend_to_year_desc = 'college_5_attend_to_year_desc',
    college_5_state_desc = 'college_5_state_desc',
    gmat_taken_month_desc = 'gmat_taken_month_desc',
    gmat_taken_year_desc = 'gmat_taken_year_desc',
    gmat_plan_month_desc = 'gmat_plan_month_desc',
    gmat_plan_year_desc = 'gmat_plan_year_desc',
    gre_taken_month_desc = 'gre_taken_month_desc',
    gre_taken_year_desc = 'gre_taken_year_desc',
    gre_plan_month_desc = 'gre_plan_month_desc',
    gre_plan_year_desc = 'gre_plan_year_desc',
    toefl_taken_month_desc = 'toefl_taken_month_desc',
    toefl_taken_year_desc = 'toefl_taken_year_desc',
    toefl_plan_month_desc = 'toefl_plan_month_desc',
    toefl_plan_year_desc = 'toefl_plan_year_desc',
    ielts_taken_month_desc = 'ielts_taken_month_desc',
    ielts_taken_year_desc = 'ielts_taken_year_desc',
    ielts_plan_month_desc = 'ielts_plan_month_desc',
    ielts_plan_year_desc = 'ielts_plan_year_desc',
    conviction_month_desc = 'conviction_month_desc',
    conviction_year_desc = 'conviction_year_desc',
    suspended_month_desc = 'suspended_month_desc',
    suspended_year_desc = 'suspended_year_desc',
    protective_month_desc = 'protective_month_desc',
    protective_year_desc = 'protective_year_desc',
    prohibited_month_desc = 'prohibited_month_desc',
    prohibited_year_desc = 'prohibited_year_desc',
    discharge_month_desc = 'discharge_month_desc',
    discharge_year_desc = 'discharge_year_desc',
}

export enum UserGeneratedAnswersEnum {
    first_name = 'first_name',
    middle_name = 'middle_name',
    last_name = 'last_name',
    preferred_name = 'preferred_name',
    prev_lname = 'prev_lname',
    gender = 'gender',
    gender_iden = 'gender_iden',
    birth_date = 'birth_date',
    ssn = 'ssn',
    phone_home = 'phone_home',
    phone_mobile = 'phone_mobile',
    optin_sms_ind = 'optin_sms_ind',
    address_line_1 = 'address_line_1',
    address_line_2 = 'address_line_2',
    city = 'city',
    state = 'state',
    zip = 'zip',
    country = 'country',
    mailing_addr_same_as_perm_addr = 'mailing_addr_same_as_perm_addr',
    mailing_address_line_1 = 'mailing_address_line_1',
    mailing_address_line_2 = 'mailing_address_line_2',
    mailing_city = 'mailing_city',
    mailing_state = 'mailing_state',
    mailing_zip = 'mailing_zip',
    mailing_country = 'mailing_country',
    mailing_addr_effective_date = 'mailing_addr_effective_date',
    citizenship = 'citizenship',
    citizenship_type = 'citizenship_type',
    birth_country = 'birth_country',
    birth_state = 'birth_state',
    citizen_country = 'citizen_country',
    alien_registration_number = 'alien_registration_number',
    us_study_radio = 'us_study_radio',
    us_residency_radio = 'us_residency_radio',
    citizenship_visa_status = 'citizenship_visa_status',
    intended_visa_type = 'intended_visa_type',
    current_visa_number = 'current_visa_number',
    ethnicity = 'ethnicity',
    race_american_indian = 'race_american_indian',
    race_asian = 'race_asian',
    race_black = 'race_black',
    race_native_hawaiian = 'race_native_hawaiian',
    race_white = 'race_white',
    tribe_radio = 'tribe_radio',
    tribe_affiliation = 'tribe_affiliation',
    tribe_affiliation_other = 'tribe_affiliation_other',
    military_status = 'military_status',
    military_branch = 'military_branch',
    military_status_specified = 'military_status_specified',
    military_tuition_assistance = 'military_tuition_assistance',
    military_self_edu_benefits = 'military_self_edu_benefits',
    military_self_veterans_benefits = 'military_self_veterans_benefits',
    military_dependent = 'military_dependent',
    military_spouse_status = 'military_spouse_status',
    military_spouse_benefits = 'military_spouse_benefits',
    law_enforcement = 'law_enforcement',
    program_type = 'program_type',
    applied_major = 'applied_major',
    app_concentration = 'app_concentration',
    ctl_int_coll_univ = 'ctl_int_coll_univ',
    ctl_int_elm_school = 'ctl_int_elm_school',
    ctl_int_ind_priv = 'ctl_int_ind_priv',
    ctl_int_sec_school = 'ctl_int_sec_school',
    ctl_int_sec_comm_coll = 'ctl_int_sec_comm_coll',
    ce_int_geotechnical = 'ce_int_geotechnical',
    ce_int_structural = 'ce_int_structural',
    ce_int_transportation = 'ce_int_transportation',
    ce_int_water_resources = 'ce_int_water_resources',
    ga_int_european_studies = 'ga_int_european_studies',
    ga_int_global_studies = 'ga_int_global_studies',
    ga_int_la_studies = 'ga_int_la_studies',
    ga_int_asian_studies = 'ga_int_asian_studies',
    ga_int_me_studies = 'ga_int_me_studies',
    hw_int_water_man = 'hw_int_water_man',
    hw_int_water_qual = 'hw_int_water_qual',
    hw_int_water_quan = 'hw_int_water_quan',
    li_int_data = 'li_int_data',
    li_int_info_tech = 'li_int_info_tech',
    li_int_acad = 'li_int_acad',
    li_int_pub = 'li_int_pub',
    li_int_school = 'li_int_school',
    li_int_special = 'li_int_special',
    li_int_arc_pres = 'li_int_arc_pres',
    li_int_youth_serv = 'li_int_youth_serv',
    se_int_appl_ba = 'se_int_appl_ba',
    se_int_fund_se = 'se_int_fund_se',
    se_int_sec_trans = 'se_int_sec_trans',
    cs_int = 'cs_int',
    geog_env_int = 'geog_env_int',
    ngem_int_engineering = 'ngem_int_engineering',
    ngem_int_management = 'ngem_int_management',
    entry_term = 'entry_term',
    parent_education = 'parent_education',
    parent_2_education = 'parent_2_education',
    prev_attend = 'prev_attend',
    prev_attend_from_month = 'prev_attend_from_month',
    prev_attend_to_month = 'prev_attend_to_month',
    prev_attend_from_year = 'prev_attend_from_year',
    prev_attend_to_year = 'prev_attend_to_year',
    sooner_id = 'sooner_id',
    college_degree = 'college_degree',
    college_gpa = 'college_gpa',
    college_credits = 'college_credits',
    college_attend_from_month = 'college_attend_from_month',
    college_attend_to_month = 'college_attend_to_month',
    college_attend_from_year = 'college_attend_from_year',
    college_attend_to_year = 'college_attend_to_year',
    college_2_degree = 'college_2_degree',
    college_2_gpa = 'college_2_gpa',
    college_2_credits = 'college_2_credits',
    college_2_attend_from_month = 'college_2_attend_from_month',
    college_2_attend_to_month = 'college_2_attend_to_month',
    college_2_attend_from_year = 'college_2_attend_from_year',
    college_2_attend_to_year = 'college_2_attend_to_year',
    college_3_degree = 'college_3_degree',
    college_3_gpa = 'college_3_gpa',
    college_3_credits = 'college_3_credits',
    college_3_attend_from_month = 'college_3_attend_from_month',
    college_3_attend_to_month = 'college_3_attend_to_month',
    college_3_attend_from_year = 'college_3_attend_from_year',
    college_3_attend_to_year = 'college_3_attend_to_year',
    college_4_degree = 'college_4_degree',
    college_4_gpa = 'college_4_gpa',
    college_4_credits = 'college_4_credits',
    college_4_attend_from_month = 'college_4_attend_from_month',
    college_4_attend_to_month = 'college_4_attend_to_month',
    college_4_attend_from_year = 'college_4_attend_from_year',
    college_4_attend_to_year = 'college_4_attend_to_year',
    college_5_degree = 'college_5_degree',
    college_5_gpa = 'college_5_gpa',
    college_5_credits = 'college_5_credits',
    college_5_attend_from_month = 'college_5_attend_from_month',
    college_5_attend_to_month = 'college_5_attend_to_month',
    college_5_attend_from_year = 'college_5_attend_from_year',
    college_5_attend_to_year = 'college_5_attend_to_year',
    college_name = 'college_name',
    college_2_name = 'college_2_name',
    college_3_name = 'college_3_name',
    college_4_name = 'college_4_name',
    college_5_name = 'college_5_name',
    college_address_line_1 = 'college_address_line_1',
    college_2_address_line_1 = 'college_2_address_line_1',
    college_3_address_line_1 = 'college_3_address_line_1',
    college_4_address_line_1 = 'college_4_address_line_1',
    college_5_address_line_1 = 'college_5_address_line_1',
    college_city = 'college_city',
    college_2_city = 'college_2_city',
    college_3_city = 'college_3_city',
    college_4_city = 'college_4_city',
    college_5_city = 'college_5_city',
    college_state = 'college_state',
    college_2_state = 'college_2_state',
    college_3_state = 'college_3_state',
    college_4_state = 'college_4_state',
    college_5_state = 'college_5_state',
    college_zip = 'college_zip',
    college_2_zip = 'college_2_zip',
    college_3_zip = 'college_3_zip',
    college_4_zip = 'college_4_zip',
    college_5_zip = 'college_5_zip',
    college_ceeb = 'college_ceeb',
    college_2_ceeb = 'college_2_ceeb',
    college_3_ceeb = 'college_3_ceeb',
    college_4_ceeb = 'college_4_ceeb',
    college_5_ceeb = 'college_5_ceeb',
    gmat_taken = 'gmat_taken',
    gmat_taken_month = 'gmat_taken_month',
    gmat_taken_year = 'gmat_taken_year',
    gmat_score_composite = 'gmat_score_composite',
    gmat_score_composite_percentile = 'gmat_score_composite_percentile',
    gmat_score_quant = 'gmat_score_quant',
    gmat_score_quant_percentile = 'gmat_score_quant_percentile',
    gmat_score_verbal = 'gmat_score_verbal',
    gmat_score_verbal_percentile = 'gmat_score_verbal_percentile',
    gmat_score_writing = 'gmat_score_writing',
    gmat_score_writing_percentile = 'gmat_score_writing_percentile',
    gmat_score_reasoning = 'gmat_score_reasoning',
    gmat_score_reasoning_percentile = 'gmat_score_reasoning_percentile',
    gmat_plan_month = 'gmat_plan_month',
    gmat_plan_year = 'gmat_plan_year',
    gre_taken = 'gre_taken',
    gre_taken_month = 'gre_taken_month',
    gre_taken_year = 'gre_taken_year',
    gre_score_verbal = 'gre_score_verbal',
    gre_score_verbal_percentile = 'gre_score_verbal_percentile',
    gre_score_quant = 'gre_score_quant',
    gre_score_quant_percentile = 'gre_score_quant_percentile',
    gre_score_writing = 'gre_score_writing',
    gre_score_writing_percentile = 'gre_score_writing_percentile',
    gre_plan_month = 'gre_plan_month',
    gre_plan_year = 'gre_plan_year',
    writing_sample = 'writing_sample',
    toefl_taken = 'toefl_taken',
    toefl_taken_month = 'toefl_taken_month',
    toefl_taken_year = 'toefl_taken_year',
    toefl_score = 'toefl_score',
    toefl_score_listening = 'toefl_score_listening',
    toefl_score_speaking = 'toefl_score_speaking',
    toefl_score_reading = 'toefl_score_reading',
    toefl_score_writing = 'toefl_score_writing',
    toefl_plan_month = 'toefl_plan_month',
    toefl_plan_year = 'toefl_plan_year',
    ielts_taken = 'ielts_taken',
    ielts_taken_month = 'ielts_taken_month',
    ielts_taken_year = 'ielts_taken_year',
    ielts_score_overall = 'ielts_score_overall',
    ielts_score_listening = 'ielts_score_listening',
    ielts_score_reading = 'ielts_score_reading',
    ielts_score_writing = 'ielts_score_writing',
    ielts_score_speaking = 'ielts_score_speaking',
    ielts_plan_month = 'ielts_plan_month',
    ielts_plan_year = 'ielts_plan_year',
    college_transcript = 'college_transcript',
    identification = 'identification',
    conviction = 'conviction',
    conviction_month = 'conviction_month',
    conviction_year = 'conviction_year',
    conviction_comment = 'conviction_comment',
    suspended = 'suspended',
    suspended_month = 'suspended_month',
    suspended_year = 'suspended_year',
    suspended_comment = 'suspended_comment',
    protective = 'protective',
    protective_month = 'protective_month',
    protective_year = 'protective_year',
    protective_comment = 'protective_comment',
    prohibited = 'prohibited',
    prohibited_month = 'prohibited_month',
    prohibited_year = 'prohibited_year',
    prohibited_comment = 'prohibited_comment',
    discharge = 'discharge',
    discharge_month = 'discharge_month',
    discharge_year = 'discharge_year',
    discharge_comment = 'discharge_comment',
    legal_agree = 'legal_agree',
}

export const StudentAnswersEnum = {
    ...AutoGeneratedAnswersEnum,
    ...UserGeneratedAnswersEnum,
};

export type StudentAnswersKeys = keyof typeof StudentAnswersEnum;
export type UserGeneratedAnswersKeys = keyof typeof UserGeneratedAnswersEnum;

export interface IQueryClientOnlyProps {
    queryClient: QueryClient;
}
